.tcell {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.avatar {
  margin-right: 15px;
}
.member-menu {
  position: absolute;
  top: 0px;
  right: 12px;
}
.actions {
  height: 50px;
}
.small-organisation-list .resource-card {
  width: 96%;
}
.small-organisation-list .small-hide {
  display: none;
}
.small-organisation-list .resource-card {
  max-height: 70px;
}
/*# sourceMappingURL=src/pages/organisations/list/organisation-card.css.map */