












































.btn-create-resource
  position fixed
  bottom: 15px
  right: 15px
  @media (min-width: 768px)
    position absolute
    right 50px
    top 175px
    bottom auto
