







































































































.index-list
  overflow auto
  margin auto
  .row-item:first-child
    margin-top 16px

  @media (min-width 768px)
    padding 0 3rem

.row-item
  width 100%
  @media (min-width 768px)
    width 75%
