.index-list {
  height: calc(100vh - 185px);
}
@media (min-width: 768px) {
  .index-list {
    padding: 0 3rem;
    height: calc(100vh - 271px);
  }
}
.index-list .row-item:first-child {
  margin-top: 16px;
}
@media (min-width: 768px) {
  .index-head,
  .resource-card {
    width: 75%;
  }
}
.resource-card {
  max-height: 82px;
  position: relative;
}
.row-item {
  width: 100%;
}
@media (min-width: 768px) {
  .tools {
    width: 50%;
  }
}
.search {
  margin-bottom: 10px;
}
@media (min-width: 768px) {
  .search {
    margin-bottom: 0px;
  }
}
/*# sourceMappingURL=src/pages/organisations/list/index.css.map */