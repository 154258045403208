









































































































































































































































































































































































































.tariff-header
  margin-top 15px
.add-tariff
  margin-top 5px
  margin-left 10px
