
































































.tariff-position
  display flex
  align-items center
  justify-content center
.remove-tariff
  margin-top 15px
.checkbox
  margin-top 20px

