.index-list {
  overflow: auto;
  margin: auto;
}
.index-list .row-item:first-child {
  margin-top: 16px;
}
@media (min-width: 768px) {
  .index-list {
    padding: 0 3rem;
  }
}
.row-item {
  width: 100%;
}
@media (min-width: 768px) {
  .row-item {
    width: 75%;
  }
}
/*# sourceMappingURL=src/pages/organisations/view/members.css.map */