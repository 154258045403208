.pricing-schedules {
  max-width: 100%;
  width: 768px;
  margin: auto;
}
p {
  margin-top: 16px;
  margin-bottom: 16px;
}
.pricing-schedule {
  max-width: 768px;
  margin: auto;
  margin-top: 30px;
}
.pricing {
  padding: 10px 0;
}
.pricing:nth-child(odd) {
  background-color: #f5f5f5;
}
.type {
  text-transform: capitalize;
  margin-top: 12px;
  margin-left: 12px;
}
/*# sourceMappingURL=src/pages/organisations/view/billing.css.map */