.top-sheet {
  padding: 0.75rem;
  position: relative;
  z-index: 100;
}
.avatar {
  width: 36px;
  height: 36px;
}
h5 {
  font-size: 18px;
  line-height: 1.2;
}
.last-active {
  font-size: 14px;
}
.actions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}
.btn-view {
  margin-top: 15px;
  width: 182px;
}
.peak-body {
  height: calc(100vh - 126px);
  overflow: auto;
}
.total-budget {
  height: 25px;
  width: 100%;
  background: #d3d3d3;
}
.content-spend {
  height: 25px;
  display: inline-block;
}
sub {
  margin-left: 16px;
}
/*# sourceMappingURL=src/pages/organisations/list/peak.css.map */