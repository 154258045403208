




























































.remove-tariff
  margin-top 15px
.checkbox
  margin-top 20px
