












































.org-notes
  max-width 100%
  width 768px
  margin auto
